<template>
  <div
    id="rgpd_fr"
    class="grid-zone text-regular"
  >
    <h2 class="bloc s3">
      RGPD
    </h2>

    <div class="bloc">
      <p>
        La présente charte pour la protection des données à caractère personnel détaille la manière dont LDE
        collecte, gère et traite les données à caractère personnel. LDE accorde une grande importance à la
        protection des données à caractère personnel de ses visiteurs, de ses partenaires et de ses utilisateurs.
        <br />
        La charte pourra être modifiée à tout moment par LDE, au gré des modifications effectuées, afin notamment de
        se conformer à toute évolution règlementaire, jurisprudentielle, éditoriale et technique. Il est conseillé
        de consulter la charte avant toute navigation et utilisation du Site.
      </p>
      <p>
        LDE respecte le règlement n°2016-679 du 27 avril 2016 relatif à la protection des personnes physiques à
        l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (dit
        "RGPD"), en complément de la loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers
        et aux libertés (dite "loi informatique et libertés") ainsi que la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l’économie numérique. Avec cette charte, LDE souhaite renforcer sa politique de protection
        des données personnelles afin que les données de l’Utilisateur soient collectées et utilisées de manière
        transparente, confidentielle et sécurisée.
      </p>
      <p>
        La charte s’applique en complément des conditions générales d’utilisation et des conditions générales de
        vente.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 1&nbsp;: Responsable de traitement
      </h2>
      <p>
        Les données à caractère personnel sont collectées par LDE agissant au nom et pour le compte de la SARL
        Artemis, nom commercial LDE, au capital de 500&nbsp;000,00&nbsp;€, immatriculée au Registre du
        Commerce et des Sociétés de Strasbourg sous le numéro 395 135 999, dont le siège social est situé au 165,
        rue du Château 67230 WESTHOUSE.<br />
        Le responsable de traitement est Frédéric FRITSCH – Directeur Général.<br />
        Le courriel du délégué à la protection des données à caractère personnel (DPO)
        <a :href="`mailto:${mailDpo}`">{{ mailDpo }}</a>.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 2&nbsp;: Identification des données collectées, finalités et exploitation des données
        traitées
      </h2>

      <h3 class="s6">
        A)Identification des données collectées
      </h3>
      <p>
        LDE, en qualité de Responsable de Traitement, est amenée à collecter, consulter, utiliser, modifier,
        stocker, transmettre et effacer des données à caractère personnel dans le cadre de ses activités.
      </p>
      <p>
        Conformément à l’article 4 du RGPD, les termes "données" ou "données à caractère personnel" désignent
        toute information se rapportant à une personne physique identifiée. Est réputée être une "personne
        physique identifiable" une personne physique qui peut être identifiée, directement ou indirectement,
        notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de
        localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité
        physique, physiologique, génétique, psychique, économique, culturelle ou sociale.
      </p>
      <p>
        Ces données sont pour la plupart collectées directement auprès des personnes concernées, lorsqu’elles,
        de manière non limitative&nbsp;:
      </p>
      <ul>
        <li>créent un espace personnel sur l’un des sites de LDE&nbsp;;</li>
        <li>effectuent une demande de renseignement&nbsp;;</li>
        <li>effectuent une commande ou par tout autre moyen ou souscrivent à un essai gratuit&nbsp;;</li>
        <li>naviguent sur le Site&nbsp;;</li>
        <li>demandent à être recontactées par un commercial de LDE&nbsp;;</li>
        <li>contactent le service client&nbsp;;</li>
        <li>rédigent un avis/commentaire visible sur le Site.</li>
      </ul>

      <h3 class="s6">
        B)&nbsp;Finalités et exploitations des données traitées
      </h3>
      <p>LDE peut traiter les données à caractère personnel dans le cadre des finalités suivantes&nbsp;:</p>

      <h4>
        1) La réalisation de traitements relatifs à la gestion de l’exécution d’un accord avec un
        client (personne concernée par la collecte de données à caractère personnel)&nbsp;:
      </h4>
      <ul>
        <li>des contrats et des comptes clients&nbsp;;</li>
        <li>des commandes et de leurs livraisons&nbsp;;</li>
        <li>de la facturation et de recouvrement.</li>
      </ul>

      <h4>
        2) La réalisation de traitements relatifs à la gestion de la relation client&nbsp;:
      </h4>
      <ul>
        <li>la gestion des avis des personnes sur des produits, services ou contenus&nbsp;;</li>
        <li>le suivi de la relation client tel que la réalisation d'enquêtes de satisfaction.</li>
      </ul>

      <h4>
        3) La réalisation de traitements relatifs à la prospection commerciale et en
        particulier&nbsp;:
      </h4>
      <ul>
        <li>la sélection de personnes pour réaliser des actions de prospection&nbsp;;</li>
        <li>la réalisation d'opérations de sollicitation&nbsp;;</li>
        <li>l'actualisation de ses fichiers de prospection.</li>
      </ul>

      <h4>
        4) La réalisation de traitement relatif à la gestion des réclamations&nbsp;:
      </h4>
      <ul>
        <li>la gestion des réclamations et du service après-vente&nbsp;;</li>
        <li>le traitement des demandes de droit d'accès, de rectification, d'opposition, d’effacement&nbsp;;</li>
        <li>la gestion des contentieux.</li>
      </ul>

      <h4>
        5) La réalisation de traitements relatifs au pilotage de l’activité commerciale&nbsp;:
      </h4>
      <ul>
        <li>la sélection de personnes pour réaliser des études et/ou des sondages&nbsp;;</li>
        <li>l’élaboration de statistiques commerciales.</li>
      </ul>

      <p>Les traitements mis en &oelig;uvre par LDE sont fondés sur&nbsp;:</p>
      <ul>
        <li>
          l’exécution du contrat pour les traitements relatifs à la gestion des contrats, des commandes, de la
          facturation et de la relation client/Utilisateur des services de LDE&nbsp;;
        </li>
        <li>
          le consentement pour la prospection commerciale à destination des personnes physiques non
          clientes&nbsp;;
        </li>
        <li>
          l’intérêt légitime pour les traitements relatifs au pilotage de l’activité commerciale, à la prospection
          commerciale pour les personnes morales&nbsp;;
        </li>
        <li>le respect d’une obligation légale pour les traitements liés à la fiscalité par exemple.</li>
      </ul>

      <p>
        Ces finalités ont été portées à la connaissance du délégué à la protection des données de LDE, qui les a
        intégrées dans son registre des activités de traitement.
      </p>
      <p>
        La communication de données personnelles dans ce cadre est volontaire. Si l’Utilisateur ne fournit pas ces
        données personnelles, il ne pourra pas bénéficier des services correspondants proposés par le Site.
      </p>
      <p>
        En règle générale, LDE ne collecte pas de données personnelles sensibles par le biais du Site. En
        fournissant volontairement à LDE des données personnelles sensibles sans qu’elles lui soient demandées,
        l’Utilisateur consent expressément à ce que ses données personnelles soient utilisées comme décrit dans
        les présentes conditions générales d’utilisation.
      </p>

      <h3 class="s6">
        C) Hébergement des données à caractère personnel
      </h3>
      <address>
        <p>
          Les données à caractère personnel sont collectées et traitées par LDE et sont hébergées au sein de
          l’Union européenne, plus précisément en France (Lille).<br />
          Le Site est hébergé par la société française OVH dont le siège est au 2, rue Kellermann 59100 Roubaix
          1007, France. SAS au capital de 10&nbsp;069&nbsp;020&nbsp;€, immatriculée au Registre
          du Commerce et des Sociétés de Lille Métropole sous le numéro 424 761 419 00045.
        </p>
        Téléphone&nbsp;: +33 9 72 10 10 07<br />
        Site internet OVH&nbsp;:
        <a
          href="https://help.ovhcloud.com/fr"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://help.ovhcloud.com/fr
        </a>
      </address>

      <h3 class="s6">
        D) Destination des données collectées
      </h3>
      <p>Les destinataires des données à caractère personnel sont les services concernés de l’entreprise LDE.</p>
      <p>
        Les données à caractère personnel ne sont pas communiquées à des tiers hormis les prestataires techniques
        externes qui interviennent dans le processus de diffusion du Site. LDE a pour règles de demander à ses
        prestataires externes de traiter les données personnelles conformément aux engagements de LDE en matière
        de confidentialité et de sécurité.
      </p>

      <h3 class="s6">
        E) Durée de conservation des données à caractère personnel
      </h3>
      <p>
        Les données sont conservées pour une durée adaptée aux finalités des traitements et conformément à la
        législation et réglementation en vigueur.
      </p>
      <p>
        Les données des clients sont conservées pendant toute la durée de la relation contractuelle et peuvent
        être conservées jusqu’à dix (10) ans après la rupture afin de respecter notamment les obligations
        comptables et fiscales qui incombent à LDE.
      </p>
      <p>
        Les données des prospects personnes physiques et morales peuvent être conservées jusqu’à trois (3) ans
        après le dernier contact émanant du prospect.
      </p>

      <h3 class="s6">
        F) Sécurité
      </h3>
      <p>
        LDE met en &oelig;uvre des mesures administratives, logiques et physiques pour protéger la sécurité de vos
        données personnelles contre le vol ainsi que l’accès, l’utilisation et la modification non autorisées. Des
        mesures techniques sont prises pour que les systèmes informatiques supportant le traitement de données à
        caractère personnel respectent les exigences internes de LDE. Des mesures organisationnelles de sécurité
        sont prises pour que les traitements de données à caractère personnel soient conformes aux exigences de
        LDE.
      </p>
      <p>
        Toutefois, il est important que l’Utilisateur sache qu’étant donné que le réseau internet est ouvert à
        tous et non sécurisé, LDE ne peut être tenu pour responsable de la sécurité des transmissions des données
        personnelles par Internet.
      </p>

      <h3 class="s6">
        G) Exercice des droits
      </h3>
      <p>
        Conformément à la réglementation en vigueur l’utilisation du Site dispose des droits suivants&nbsp;:
      </p>
      <ul>
        <li>droit d'accès&nbsp;;</li>
        <li>
          droit de rectification si vos données sont erronées ou obsolètes (mise à jour et complétude)&nbsp;;
        </li>
        <li>droit à l’oubli pour effacer vos données&nbsp;;</li>
        <li>droit de retirer à tout moment un consentement&nbsp;;</li>
        <li>droit à la limitation du traitement&nbsp;;</li>
        <li>droit d'opposition aux traitement des données personnelles&nbsp;;</li>
        <li>
          droit à la portabilité des données que vous avez fournies, lorsque ces données font l'objet de
          traitements automatisés fondés sur leur consentement ou un contrat&nbsp;;
        </li>
        <li>
          droit de définir le sort des données à caractère personnel à la mort d’un Utilisateur et de choisir à
          qui LDE devra communiquer ses données (personne préalablement désignée).
        </li>
      </ul>
      <p>
        Pour plus d’informations sur le sujet&nbsp;:
        <a
          href="https://www.cnil.fr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://www.cnil.fr/
        </a>.
      </p>
      <address>
        Ces droits peuvent être exercés en contactant LDE aux adresses suivantes&nbsp;:<br />
        Par courrier&nbsp;: SARL Artemis – LDE - 165, rue du Château 67230 WESTHOUSE (France)<br />
        Par courriel&nbsp;: <a :href="`mailto:${mailDpo}`">{{ mailDpo }}</a>
      </address>
      <p>
        Enfin l’Utilisateur de LDE peut déposer une réclamation auprès des autorités de contrôle, et notamment de
        la CNIL&nbsp;:
        <a
          href="https://www.cnil.fr/fr/plaintes"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://www.cnil.fr/fr/plaintes
        </a>.
      </p>
      <p>
        En cas d’exercice de vos droits, vous devez justifier de votre identité par tous les moyens. Toutefois, si
        des doutes raisonnables persistent quant à l’exactitude de votre identité, LDE pourra demander des
        informations supplémentaires telles que la photocopie de votre carte d’identité. Vos requêtes seront
        traitées sous un mois.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 3&nbsp;: Cookies
      </h2>

      <h3 class="s6">
        A) Définition
      </h3>
      <p>
        Les cookies sont de petits fichiers stockés temporairement sur le terminal de l’Utilisateur par le
        navigateur, utilisés par le Site pour envoyer des informations au navigateur de l’Utilisateur permettant
        au navigateur de renvoyer des informations au site d’origine (comme un identifiant de session, le choix
        d’une langue...).
      </p>
      <p>
        La durée de vie maximale des cookies ou traceurs est de 13 mois. Les cookies fonctionnels et techniques ne
        nécessitent pas le recueil du consentement de l’Utilisateur.
      </p>

      <h3 class="s6">
        B) Utilisation des cookies
      </h3>
      <p>
        En accédant au Site de LDE, l’Utilisateur a la possibilité de choisir les cookies qu’il souhaite accepter
        ou refuser. Certains cookies sont nécessaires au bon fonctionnement du Site. D’autres cookies de
        fonctionnalités, liés à des boutons de partage ou d’analyse d’audience, ne s’installent pas sans votre
        consentement (de tels cookies ne sont pas actuellement utilisés par LDE). Refuser ces cookies peut
        conduire à l’absence de certaines fonctionnalités du Site, mais ne conduit pas à altérer une navigation
        normale sur le Site.
      </p>

      <h3 class="s6">
        C) Finalités générales
      </h3>
      <p>
        Les finalités générales des cookies et autres traceurs que LDE est susceptible d’utiliser sont les
        suivantes&nbsp;:
      </p>
      <ul>
        <li>Usage du Site (non soumis au consentement), dont cookies&nbsp;:</li>
        <li class="no-list-style">
          <ul>
            <li>d’identifiants de session&nbsp;;</li>
            <li>d’authentification&nbsp;;</li>
            <li>de mesures d’audience&nbsp;;</li>
            <li>de session nécessaire à la sécurité.</li>
          </ul>
        </li>
        <li>Fonctionnalités (soumis au consentement), dont cookies&nbsp;:</li>
        <li class="no-list-style">
          <ul>
            <li>d’analyse d’audience soumis au consentement&nbsp;;</li>
            <li>
              relatifs aux réseaux sociaux afin de permettre de créer un lien ou de partager des liens
              rapidement sur les réseaux sociaux&nbsp;;
            </li>
            <li>permettant de créer des liens vers des services de partage de vidéo.</li>
          </ul>
        </li>
      </ul>
      <p>Ce dernier type de cookies n'est pas encore utilisé par LDE.</p>
      <p>
        Les choix de l’Utilisateur sont conservés pendant 6 mois. A l’issue de cette durée, LDE sollicitera de
        nouveau ses choix sur les cookies.
      </p>
      <p>
        L’Utilisateur peut retirer son consentement à tout moment en accédant au module de gestion, accessible en
        bas de page (depuis une page nécessitant une demande de consentement).
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 4&nbsp;: Notification de modifications de la présente charte
      </h2>
      <p>Pour vérifier les mises à jour de ces conditions, il convient de consulter régulièrement cette page.</p>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page du RGPD en français.
 */
export default {
  name: "RgpdFr",
  computed: {
    mailDpo() {
      return config.mailsFr.dpo;
    },
  },
};
</script>

<style lang="scss"></style>
