<template>
  <div id="statuts_services">
    <h1 class="flex-vcenter bold">
      {{ $t("services.statuts-des-services") }}
    </h1>
    <!-- TODO: services LDE pas dans la v1, à voir plus tard -->
    <!-- <section id="services_lde">
      </section> -->

    <section id="services_editeurs">
      <h2 class="s5 bold">
        {{ $t("services.services-editeurs") }}
      </h2>
      <ul class="list">
        <li
          v-for="etats, editeur in statutsEditeurs"
          :key="editeur"
        >
          <h3 class="text-regular bold">
            {{ editeur }}
          </h3>
          <ul class="sublist">
            <li
              v-for="service in etats"
              :key="service.id"
              class="flex-vcenter s5 light"
              :class="{ active: service.status_code.toString().startsWith('2'), }"
              v-html="service.nom"
            />
          </ul>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import Api from "@/modules/axios";

/**
 * Page des statuts des services LDE et des éditeurs.
 */
export default {
  name: "StatutsServices",
  data() {
    return {
      statutsEditeurs: {},
      isOpened: {},
    };
  },
  mounted() {
    this.fetchStatutsEditeurs();
  },
  methods: {
    /**
     * Récupère les statuts des services des éditeurs.
     * @public
     */
    fetchStatutsEditeurs() {
      Api()
        .get("/statut-editeur/")
        .then((res) => {
          this.statutsEditeurs = res.data;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_statuts_services.scss";
</style>
