<template>
  <div id="rgpd">
    <RgpdDe v-if="country === 'de'" />
    <RgpdFr v-else />
  </div>
</template>

<script>
import RgpdFr from "@/components/legal/RgpdFr.vue";
import RgpdDe from "@/components/legal/RgpdDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche la page du RGPD des services.
 */
export default {
  name: "Rgpd",
  components: {
    RgpdFr,
    RgpdDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
