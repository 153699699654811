<template>
  <div
    id="rgpd_de"
    class="grid-zone text-regular"
  >
    <h2 class="bloc s3">
      Datenschutz
    </h2>

    <p class="bloc bold">
      Datum: Oktober 2021
    </p>

    <div class="bloc">
      <h2 class="s4 light">
        1: Für die Verarbeitung Verantwortlicher, Datenschutzbeauftragter
      </h2>
      <p>
        Verantwortlich im Sinne der Datenschutz-Grundverordnung und anderer Datenschutzgesetze sowie sonstiger
        datenschutzrechtlicher Bestimmungen ist nach Artikel 4 Abs. 3 DSGVO die
      </p>
      <address>
        LDE GmbH & Co.KG<br />
        Robert-Koch-Str. 35<br />
        77694 Kehl<br />
        Tel.: +49 (0)7851 48599-0<br />
        <a :href="`mailto:${mailLdeDe}`">{{ mailLdeDe }}</a>
      </address>
      <address>
        Handelsregistereintrag: Freiburg HRA 370799<br />
        vertreten durch<br />
        LDE Verwaltungs GmbH<br />
        Robert-Koch-Str. 35<br />
        77694 Kehl
      </address>
      <address>
        Handelsregistereintrag: Freiburg HRB 372026<br />
        Geschäftsführer: Frédéric FRITSCH
      </address>
      <address>
        Datenschutzbeauftragter: Hervé Poulet – <a :href="`mailto:${mailLdeDe}`">{{ mailLdeDe }}</a>
      </address>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        2: Erhebung und Speicherung personenbezogener Daten, Art und Zweck der Verwendung
      </h2>

      <h3 class="s6">
        A) Beim Besuch der Website
      </h3>
      <p>
        Beim Aufrufen unserer Website
        <a
          href="www.poplab.schule"
          target="_blank"
          rel="noreferrer noopener"
        >
          www.poplab.schule
        </a>
        werden durch den von Ihnen verwendeten Browser automatisch Informationen an den Server unserer Website gesendet.
        Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne
        Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
      </p>
      <ul>
        <li>IP-Adresse des anfragenden Rechners;</li>
        <li>Datum und Uhrzeit des Zugriffs;</li>
        <li>Name und URL der abgerufenen Datei;</li>
        <li>Website, von der aus der Zugriff erfolgt (Referrer-URL);</li>
        <li>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
      </ul>
      <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
      <ul>
        <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website;</li>
        <li>Gewährleistung einer komfortablen Nutzung unserer Website;</li>
        <li>Auswertung der Systemsicherheit und -stabilität sowie;</li>
        <li>Zu weiteren administrativen Zwecken.</li>
      </ul>
      <p>
        Die Verarbeitung erfolgt entsprechend Art. 6 Abs. 1 S. 1 lit. f DSGVO nur zu den oben genannten Zwecken. In
        keinem Fall werden die erhobenen Daten dafür verwendet, Rückschlüsse auf Ihre Person zu ziehen.
      </p>
      <p>Erläuterungen zu den von uns verwendeten Cookies finden Sie unter Nr. 4 dieser Datenschutzerklärung.</p>

      <h3 class="s6">
        B) Bei Kontaktaufnahme per E-Mail, Post, Fax oder Telefon
      </h3>
      <p>
        Wenn Sie uns kontaktieren, werden für die Bearbeitung Ihrer Anfrage die von Ihnen übermittelten Daten zum
        Zwecke der Bearbeitung Ihrer Anfrage nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
        erteilten Einwilligung verarbeitet.
      </p>
      <p>Diese Daten werden nach Erledigung Ihrer Anfrage automatisch gelöscht.</p>

      <h3 class="s6">
        C) Verarbeitung von Kundendaten
      </h3>
      <p>
        Personenbezogene Daten unserer Kunden sowie der dortigen Ansprechpartner werden nach Art. 6 Abs. 1 S. 1 lit. b
        DSGVO verarbeitet, soweit dies für die Erfüllung des Vertrags erforderlich ist. Diese Daten werden nach Ablauf
        der jeweils gesetzlichen Aufbewahrungsfrist gelöscht, es sei denn, dass wir nach Artikel 6 Abs. 1 S. 1 lit. c
        DSGVO aufgrund von steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten zu einer längeren
        Speicherung verpflichtet sind, ein berechtigtes Interesse an der weiteren Speicherung nach Artikel 6 Abs. 1 S. 1
        lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen besteht, oder Sie in eine
        darüber hinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben.
      </p>

      <h3 class="s6">
        D) Auftragsverarbeitung durch uns
      </h3>
      <p>
        Soweit wir für unsere Kunden im Rahmen der Auftragsverarbeitung tätig werden, insbesondere im Rahmen der
        Schulbuchverwaltung mit Cristal Web, gilt die jeweilige Datenschutzerklärung des Auftraggebers.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        3: Weitergabe von Daten
      </h2>
      <p>Eine Übermittlung Ihrer personenbezogenen Daten an Dritte findet nur statt, wenn:</p>
      <ul>
        <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben;</li>
        <li>
          Die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
          schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben;
        </li>
        <li>Eine gesetzliche Verpflichtung für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO besteht, sowie;</li>
        <li>
          Sie Weitergabe gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von
          Vertragsverhältnissen mit Ihnen erforderlich ist.
        </li>
      </ul>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        4: Cookies
      </h2>
      <p>
        Wir setzen auf unserer Seite Cookies ein. Diese kleinen Dateien werden von Ihrem Browser automatisch erstellt
        und auf Ihrem Endgerät gespeichert, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen
        Schaden an, sie enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
      </p>
      <p>
        Im Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten
        Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität
        erhalten.
      </p>
      <p>
        Der Einsatz von Cookies dient dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. Sogenannte
        Session-Cookies erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach
        Verlassen unserer Seite automatisch gelöscht.
      </p>
      <p>
        Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für
        einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut,
        um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche
        Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
      </p>
      <p>
        Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen
        sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
      </p>
      <p>
        Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass
        keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie
        angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle
        Funktionen unserer Website nutzen können.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        5: Analyse-Tools und Social Media Plug-ins
      </h2>
      <p>Wir setzen keine Analyse Tools und keine Social Media Plug-ins ein.</p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        6: Betroffenenrechte
      </h2>
      <p>Sie haben das Recht:</p>
      <ul>
        <li>
          Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen (Art. 15 DSGVO). Hierzu zählen
          die Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von
          Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das
          Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das
          Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
          über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
          aussagekräftigen Informationen zu deren Einzelheiten verlangen;
        </li>
        <li>
          Unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
          Daten zu verlangen (Art. 16 DSGVO);
        </li>
        <li>
          Die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen
          Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen erforderlich ist (Art. 17 DSGVO);
        </li>
        <li>
          Die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
          Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir
          die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben
          (Art. 18 DSGVO);
        </li>
        <li>
          Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen
          (Art. 20 DSGVO);
        </li>
        <li>
          Sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde
          Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Firmensitzes wenden (Art. 77 DSGVO);.
        </li>
        <li>
          Sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde
          Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Firmensitzes wenden (Art. 77 DSGVO).
        </li>
      </ul>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        7: Widerspruchsrecht
      </h2>
      <p>
        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f
        DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer
        personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation
        ergeben, oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles
        Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.
      </p>
      <p>
        Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an
        <a :href="`mailto:${mailLdeDe}`">{{ mailLdeDe }}</a>
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        8: Datensicherheit
      </h2>
      <p>
        Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung
        mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es
        sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen
        wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes
        verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise
        Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
      </p>
      <p>
        Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten
        gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen
        den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
        Entwicklung fortlaufend verbessert.
      </p>
      <p>
        Das Hosting von
        <a
          href="https://poplab.schule"
          target="blank"
          rel="noreferrer noopener"
        >
          https://poplab.schule
        </a>
        sowie die Verarbeitung personenbezogener Daten erfolgen aus-schließlich innerhalb der Europäischen Union.
      </p>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page du RGPD en allemand.
 */
export default {
  name: "RgpdDe",
  computed: {
    mailLdeDe() {
      return config.mailsDe.lde;
    },
  },
};
</script>

<style lang="scss"></style>
